const s3ToImageCDN = (imagePath) => {
  if (!imagePath) {
    console.log('No path provided.');
    return imagePath;
  }

  return `${imagePath?.replace(
    'https://diner.s3.eu-central-1.amazonaws.com',
    process.env.REACT_APP_IMAGE_CDN_URL
  )}?auto=compress`;
};

export default s3ToImageCDN;
