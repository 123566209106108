import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CallIcon } from 'assets/svg/call-icon.svg';
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import { motion } from 'framer-motion';

const CallButton = ({ phoneNumber, text, theme }) => {
  if (!phoneNumber) return null;

  const handleClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <CallButtonWrapper
      whileTap={{ scale: 0.9 }}
      theme={theme}
      onClick={handleClick}
    >
      <CallIcon color={colors[theme].primary} />
      <CallButtonText theme={theme}>{text}</CallButtonText>
    </CallButtonWrapper>
  );
};

export default CallButton;

export const CallButtonWrapper = styled(motion.div)`
  position: absolute;
  top: 44px;
  right: 8vw;
  z-index: 99;
  width: 74px;
  height: 30px;
  border-radius: 10px;
  box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props) => colors[props.theme].offWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  cursor: pointer;
`;

const CallButtonText = styled.div`
  font-family: ${fonts.barlow};
  font-size: 8px;
  font-weight: 800;
  line-height: 1.25;
  color: ${(props) => colors[props.theme].primary};
`;
