import { css } from 'styled-components';
import blurs from './blurs';

const headers = {
  DEFAULT: css`
    padding-bottom: 40px;
    background-image: linear-gradient(
      205deg,
      rgba(242, 124, 67, 1) 0%,
      rgba(248, 65, 120, 1) 55%
    );
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 33px));
  `,
  DARK: css`
    padding-bottom: 40px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 33px));
    ${blurs.dark}
  `,
  LIGHT: css`
    padding-bottom: 40px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 33px));
    ${blurs.xDark}
  `,
  HAPPYMOONS: css`
    padding-bottom: 40px;
    background-image: linear-gradient(205deg, #e10b17 0%, #e10b17 55%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 33px));
  `,
  GREEN: css`
    padding-bottom: 40px;
    background-image: linear-gradient(205deg, #6b8e23 0%, #6b8e23 55%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 33px));
  `,
  BEEVES: css`
    padding-bottom: 40px;
    background-image: linear-gradient(205deg, #a31927 0%, #a31927 55%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 33px));
  `,
};

export default headers;
