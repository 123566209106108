/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'utils/API';

const SET_STATUS = 'MASTERPASS/SET_STATUS';
const INITIALIZE = 'MASTERPASS/INITIALIZE';
const FETCH_TOKEN = 'MASTERPASS/FETCH_TOKEN';
export const STATUSES = {
  NO_ACCOUNT: '000000',
  NOT_LINKED: '1100',
  LINKED: '11100',
  SUCCESS: 'SUCCESS',
  REGISTER_CARD: 'REGISTER_CARD',
};

export const setStatus = createAction(SET_STATUS);
export const fetchToken = createAsyncThunk(FETCH_TOKEN, async () => {
  try {
    const { data } = await API.get(`/pay/token`);
    return data;
  } catch (error) {
    console.error('Error :: Masterpass :: fetchToken ::', error);
    throw error;
  }
});
export const initialize = createAsyncThunk(INITIALIZE, async () => {
  const {
    REACT_APP_MASTERPASS_CLIENT_ID,
    REACT_APP_MASTERPASS_URL,
  } = process.env;

  MFS.setClientId(REACT_APP_MASTERPASS_CLIENT_ID);
  MFS.setAddress(REACT_APP_MASTERPASS_URL);

  // const script = document.createElement('script');
  // script.src = `${process.env.PUBLIC_URL}/js/mfs-client.min.js`;
  // script.async = true;
  // script.onload = () => {
  //   return true;
  // };
  // document.body.appendChild(script);
});

const masterpassSlice = createSlice({
  name: 'masterpass',
  initialState: {
    isInitializing: false,
    initialized: false,
    status: null,
    token: null,
    reqRefNo: null,
    fetchingToken: false,
  },
  extraReducers: {
    // INITIALIZE
    [initialize.pending]: (state) => {
      state.isInitializing = true;
    },
    [initialize.fulfilled]: (state) => {
      state.isInitializing = false;
      state.initialized = true;
    },
    [initialize.rejected]: (state) => {
      state.isInitializing = false;
      state.initialized = false;
    },
    // STATUS
    [setStatus]: (state, action) => {
      state.status = action.payload;
    },
    // TOKEN
    [fetchToken.pending]: (state) => {
      state.fetchingToken = true;
    },
    [fetchToken.fulfilled]: (state, action) => {
      state.fetchingToken = false;
      state.token = action.payload.token;
      state.reqRefNo = action.payload.reqRefNo;
    },
    [fetchToken.rejected]: (state) => {
      state.fetchingToken = false;
      state.token = null;
      state.reqRefNo = null;
    },
  },
});

export default masterpassSlice;
