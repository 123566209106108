import { createSlice, createAction } from '@reduxjs/toolkit'

export const setActiveCategory = createAction('setActiveCategory')
export const setIsTabVisible = createAction('setIsTabVisible')

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    id: '',
    changeByScroll: false,
    isTabsVisible: false,
  },
  extraReducers: {
    [setActiveCategory]: (state, action) => {
      return {
        ...state,
        id: action.payload.id,
        changeByScroll: action.payload.changeByScroll,
      }
    },
    [setIsTabVisible]: (state, action) => {
      state.isTabsVisible = action.payload
    },
  },
})

export default categorySlice
