import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { LanguageSwitcher } from 'components';
import { useTranslation } from 'react-i18next';

const getMenuLanguages = (menu) => {
  let languages = null;

  if (menu?.localizations) {
    languages = [menu?.language, ...Object.keys(menu.localizations)];
  }

  return languages;
};

const getInitialLanguage = (languages, browserLanguage) => {
  if (!languages) return false;

  return (
    languages.find((l) => l.toLowerCase() === browserLanguage.toLowerCase()) ||
    languages[0]
  );
};

const MenuButton = ({ menu, onClick, text }) => {
  const { i18n } = useTranslation();
  const languages = getMenuLanguages(menu);
  const [language, setLanguage] = useState(
    getInitialLanguage(languages, i18n.languages[0])
  );

  const renderLanguages = !!languages?.length;

  return (
    <ButtonWrapper>
      <PrimaryButton
        className={renderLanguages ? 'rounded-l-lg' : 'rounded-lg'}
        onClick={() => onClick(menu.id, language)}
      >
        <MenuButtonText>{text}</MenuButtonText>
      </PrimaryButton>
      {renderLanguages && (
        <LanguageSwitcher
          languages={languages}
          activeLanguage={language}
          onLanguageChange={setLanguage}
        />
      )}
    </ButtonWrapper>
  );
};

export default MenuButton;

const ButtonWrapper = styled(motion.div)`
  width: 90%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  box-shadow: 0px 13px 16px #00000029;
  position: relative;
  cursor: pointer;
`;

const PrimaryButton = styled(motion.button)`
  flex: 1 auto;
  height: 3.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.text};
  cursor: pointer;
`;

const MenuButtonText = styled.div`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.19;
  color: ${(props) => props.theme.colors.background};
`;
