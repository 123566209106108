/* eslint-disable no-undef */
import React, { useState } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { shallowEqual, useSelector } from 'react-redux';
import { useModali } from 'modali';
import { Button } from 'components';
import MasterpassPng from 'assets/png/masterpass.png';
import OTPForm from './OTPForm';

const LinkToClient = ({ onLinked }) => {
  const [submitting, setSubmitting] = useState(false);

  const token = useSelector((state) => state.masterpass.token);
  const reqRefNo = useSelector((state) => state.masterpass.reqRefNo);
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const [otpModal, toggleOtpModal] = useModali({
    animated: true,
    large: true,
    centered: true,
  });

  const onValidate = () => {
    toggleOtpModal();
    return onLinked();
  };

  const mfsResponseHandler = (status, response) => {
    if (response?.responseCode === '5001') {
      return toggleOtpModal();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    setSubmitting(true);

    MFS.linkCardToClient($(e.nativeEvent.target), mfsResponseHandler);
  };

  return (
    <div
      className="px-20 pb-32 pt-10 w-full flex flex-col fixed bottom-0 rounded-t-8 h-1/2 shadow-24 justify-between items-center"
      id="PaymentForm"
    >
      <img className="h-12 w-56 " src={MasterpassPng} alt="masterpass" />
      <form
        action=""
        method="POST"
        id="linkToClient-form"
        className="form-horizontal"
        onSubmit={onSubmit}
      >
        {/* MFS purchase and register parameters start */}
        <input type="hidden" name="token" value={token} />
        <input type="hidden" name="referenceNo" value={reqRefNo} />
        <input type="hidden" name="sendSmsLanguage" value="tur" />
        <input
          hidden
          name="msisdn"
          type="text"
          placeholder={`90${user.phoneNumber}`}
          value={`90${user.phoneNumber}`}
          className="input-xlarge"
        />
        {/* <input type="hidden" name="installmentCount" value="2" />
		     <input type="hidden" name="rewardName" value="BONUS" />   
		     <input type="hidden" name="rewardValue" value="100" /> */}
        {/* MFS purchase and register operation parameters end */}

        <input type="hidden" name="sendSms" value="N" />
        <input type="hidden" name="actionType" value="A" />
        <div className="control-group text-center">
          Masterpass’e kayıtlı kartlarınız bulunmakta kullanmak ister misiniz?
        </div>
        <Button
          className="w-full  text-2xl left-0 fixed bottom-0 z-20 py-6 px-10 rounded-t-8 rounded-b-0"
          type="submit"
          loading={submitting}
        >
          Kullanmak istiyorum
        </Button>
      </form>
      <OTPForm modal={otpModal} onValidate={onValidate} reqRefNo={reqRefNo} />
    </div>
  );
};

export default LinkToClient;
