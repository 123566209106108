import React from 'react';
import styled from 'styled-components';
import headers from 'theme/headers';

const Header = ({ leftIcon, logo, theme = 'DEFAULT', rightIcon, children }) => {
  const leftBox = leftIcon || <Filler />;
  const rightBox = rightIcon || <Filler />;
  return (
    <HeaderWrapper theme={theme}>
      <div className="flex justify-between w-full items-start">
        <div>{leftBox}</div>
        <div>{logo} </div>
        <div>{rightBox}</div>
      </div>
      {children}
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  position: fixed;
  top: 0;
  padding: 1rem;
  width: 100vw;
  min-height: 20vh;
  max-height: 30vh;
  z-index: 1;
  ${(props) => headers[props.theme]};
`;

const Filler = styled.div`
  width: 45px;
`;
