import { API } from 'utils/API';

const fetchFoodItem = async (key, { foodItemId, lang }) => {
  const { data } = await API.get(
    `fooditems/${foodItemId}/detail`,
    lang && {
      headers: {
        'Accept-Language': lang,
      },
    }
  );
  return data;
};
export { fetchFoodItem };
