import { LazyImage } from 'components';
import React from 'react';
import styled from 'styled-components';
import { getOptimizedImage } from 'utils';
import { motion } from 'framer-motion';

export const GridItem = ({ category, onClick }) => {
  const optimizedImage = getOptimizedImage(category?.imagePath, '&w=400');
  const tinyImage = getOptimizedImage(category?.imagePath, '&h=50');
  const noGridImage = !(optimizedImage || category.image);

  return (
    <GridItemContainer whileTap={{ scale: 0.95 }} onClick={onClick}>
      <LazyImage
        placeholder={tinyImage}
        src={optimizedImage || category.image}
        alt={category.name}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      <LabelContainer noGridImage={noGridImage}>
        <Label>{category.name}</Label>
      </LabelContainer>
    </GridItemContainer>
  );
};

export default GridItem;

const GridItemContainer = styled(motion.div)`
  border-radius: 10px;
  height: 200px;
  box-shadow: 0 13px 16px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  position: relative;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex: 1 auto;
  justify-content: center;
  align-items: ${(props) => (props.noGridImage ? 'center' : 'flex-end')};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: ${(props) => (props.noGridImage ? '100%' : 'auto')};
  min-height: 2vw;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  border-radius: ${(props) => (props.noGridImage ? '10px' : '0 0 10px 10px')};
`;

export const Label = styled.span`
  font-size: 1.1rem;
  font-family: ${(props) => props.theme.fonts.condensed};
  color: ${(props) => props.theme.colors.background};
  text-align: center;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
`;
