import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setActiveCategory } from 'store/slices/Category';
import colors from 'theme/colors';
import blurs from 'theme/blurs';
import { fonts } from 'theme';

function Tab({ categoryName, categoryId, tabsRef, theme }) {
  const tabRef = useRef(null);
  const categoryState = useSelector((state) => state.category, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    const scrollToSelectedTab = () => {
      const tabOffset = tabRef.current.offsetLeft;
      const viewPortWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      tabsRef.current.scroll({
        left: tabOffset - viewPortWidth / 2 + 135 / 2,
        behavior: 'smooth',
      });
    };
    if (categoryState.id === categoryId) {
      scrollToSelectedTab();
    }
  }, [categoryState.id, categoryId, tabsRef]);

  const isActive = categoryId === categoryState.id;
  return (
    <TabContainer
      ref={tabRef}
      theme={theme}
      isActive={isActive}
      onClick={() => {
        dispatch(
          setActiveCategory({
            id: categoryId,
            changeByScroll: false,
          })
        );
      }}
    >
      <TabTitle theme={theme} isActive={isActive}>
        {categoryName}
      </TabTitle>
    </TabContainer>
  );
}

export default Tab;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  width: 135px;
  height: ${(props) => (props.isActive ? '50px' : '44px')};
  padding: 0px 2.66vw;
  border-radius: 10px;
  ${(props) => (props.isActive ? blurs.xDark : blurs.light)};
`;

export const TabTitle = styled.div`
  font-family: ${fonts.condensed};
  font-size: 16px;
  font-size: 1.1em;
  line-height: 1.17;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(props) => colors[props.theme].text} ;
  opacity: ${(props) => (props.isActive ? '1' : '0.7')};
  color: ${(props) =>
    props.isActive
      ? colors[props.theme].offWhite
      : colors[props.theme].secondary};
}
`;
