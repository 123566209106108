import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { ReactComponent as ARIcon } from 'assets/svg/arkit-icon.svg';
import { Notification } from 'components';
import DotLoader from 'react-spinners/DotLoader';
import { INAPP_BROWSER_KEY } from 'static/localStorage/keys';
import { logEvent } from 'analytics';
import { getOptimizedImage, s3ToCloudfont } from 'utils';
import blurs from 'theme/blurs';
import { useRestaurant } from 'hooks';
import { useParams } from 'react-router-dom';

const placeHolderImage = require('assets/svg/menu-item-placeholder.svg');

const ARButton = ({ name, restaurant }) => {
  const [isVisited, setIsVisited] = useState(false);

  return (
    <ARButtonWrapper
      slot="ar-button"
      isVisited={isVisited}
      onClick={() => {
        // localStorage.setItem(AR_TOOLTIP_KEY, true);
        setIsVisited(true);
        logEvent({
          action: 'AR_SESSION',
          payload: {
            name,
            restaurantId: restaurant?.id,
            restaurant: restaurant?.name,
            chain: restaurant?.chain,
          },
        });
      }}
    >
      <ARIcon width="70px" height="45px" fill="red" />
    </ARButtonWrapper>
  );
};

const NotificationContext = () => {
  return (
    <ContextSpan>
      Bu yemeği masanda görüntülemek için üç noktaya basıp, &ldquo;
      <b>Safari</b>
      &apos;de Aç&rdquo; seçeneğine dokunabilirsin
    </ContextSpan>
  );
};
const ArView = ({ models = {}, name, image, modalExpanded, isToggled }) => {
  const modelViewerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { usdz, glb } = models || {};
  const [is3DLoaded, setis3DLoaded] = useState(false);
  const optimizedImage = getOptimizedImage(image, '&q=50');
  const { restaurantId } = useParams();
  const { data: restaurant } = useRestaurant({ restaurantId });

  useEffect(() => {
    const ModelViewerRef = modelViewerRef.current;
    const loadHandler = (e) => {
      setis3DLoaded(true);
      logEvent({
        action: 'MODEL_LOADED',
        payload: { name },
      });
    };

    if (ModelViewerRef) {
      ModelViewerRef.addEventListener('load', loadHandler);
      // ModelViewerRef.addEventListener(
      //   'quick-look-button-tapped',
      //   arStatusHandler
      // );
    }
    return () => {
      if (ModelViewerRef) {
        ModelViewerRef.removeEventListener('load', loadHandler);
        // ModelViewerRef.removeEventListener(
        //   'quick-look-button-tapped',
        //   arStatusHandler
        // );
      }
    };
  }, [modelViewerRef, name]);

  useEffect(() => {
    const script = document.createElement('script');
    const legacyScript = document.createElement('script');

    script.type = 'module';
    script.src =
      'https://unpkg.com/@google/model-viewer@1.2.1/dist/model-viewer.min.js';
    script.async = true;

    legacyScript.noModule = true;
    legacyScript.src =
      'https://unpkg.com/@google/model-viewer/dist/model-viewer-legacy.js';
    legacyScript.async = true;

    document.body.appendChild(script);
    document.body.appendChild(legacyScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(legacyScript);
    };
  }, []);

  if (usdz && glb && isToggled) {
    return (
      <>
        <Notification
          storageKey={INAPP_BROWSER_KEY}
          hasArrow
          context={<NotificationContext />}
        />
        <ModelViewerWrapper modalExpanded={modalExpanded}>
          <model-viewer
            id="reveal"
            src={s3ToCloudfont(glb)}
            ref={modelViewerRef}
            ios-src={s3ToCloudfont(usdz)}
            alt={name}
            exposure={0.62}
            loading="eager"
            camera-target="0m -0.1m 0m"
            camera-orbit="45deg 55deg 2m"
            min-camera-orbit="auto auto 1.8m"
            max-camera-orbit="auto auto 2m"
            max-field-of-view="20deg"
            min-field-of-view="15deg"
            shadow-intensity="0.8"
            ar
            ar-modes="webxr scene-viewer quick-look fallback"
            ar-scale="auto"
            auto-rotate
            quick-look-browsers="safari chrome"
            camera-controls
          >
            <ModelPreview
              slot="poster"
              is3DLoaded={is3DLoaded}
              poster={optimizedImage || placeHolderImage}
            >
              {!is3DLoaded && (
                <LoaderWrapper>
                  <DotLoader color="black" loading margin={0} size={50} />
                </LoaderWrapper>
              )}
            </ModelPreview>
            <ARButton name={name} restaurant={restaurant} />
          </model-viewer>
        </ModelViewerWrapper>
      </>
    );
  }
  if (image && !isToggled) {
    if (isOpen) {
      return (
        <>
          <ImageWrapper
            modalExpanded={modalExpanded}
            onClick={() => setIsOpen(true)}
          >
            <Image alt={name} src={optimizedImage || placeHolderImage} />
          </ImageWrapper>
          <Lightbox
            mainSrc={optimizedImage || placeHolderImage}
            onCloseRequest={() => setIsOpen(false)}
          />
        </>
      );
    }
    return (
      <ImageWrapper onClick={() => setIsOpen(true)}>
        <Image alt={name} src={optimizedImage || placeHolderImage} />
      </ImageWrapper>
    );
  }
  return <Filler />;
};

export default ArView;

const Filler = styled.div`
  margin: 10vw;
`;

const ModelViewerWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.modalExpanded ? '0' : '-1')};
  overflow: hidden;
`;

export const ModelPreview = styled.div`
  width: 100%;
  height: ${(props) => (props.is3DLoaded ? '100%' : '60%')};
  display: block;
  position: absolute;
  background-image: ${(props) => `url(${props.poster});`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.modalExpanded ? '25%' : '60%')};
  z-index: ${(props) => (props.modalExpanded ? '0' : '-2')};
  display: flex;
`;
export const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const ARButtonWrapper = styled.button`
  display: flex;
  flex-direction: column;
  border: none;
  right: 16px;
  bottom: 45%;
  position: absolute;
  z-index: 999;
  &:before {
    display: ${(props) => (props.isVisited ? 'none' : 'block')};
    content: '';
    border-color: transparent;
    position: absolute;
    left: 40px;
    top: 40px;
    z-index: 1;
    margin-left: -10px;
    border-width: 6px;
    border-style: solid;
    transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
    border-color: ${(props) =>
      props.isVisited
        ? 'transparent'
        : 'rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0)'};
  }
  &:after {
    display: ${(props) => (props.isVisited ? 'none' : 'block')};
    font-family: ${(props) => props.theme.fonts.barlow};
    font-size: 0.8rem;
    font-weight: normal;
    word-spacing: 1px;
    content: 'Masaya yerleştirmek için dokununuz';
    white-space: pre;
    position: absolute;
    right: -10px;
    top: 52px;
    border: none;
    border-radius: 20px;
    transition: opacity 0.5s ease;
    -webkit-transition: opacity 0.5s ease;
    color: white;
    padding: 13px;
    ${blurs.xDark}
  }
`;
const ContextSpan = styled.span`
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  position: relative;
  display: inline-block;
`;
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
`;
