import { fetchMenu } from 'queries';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { updateMenu } from 'store/slices/Cart';

const useMenu = ({ menuId, language }) => {
  const dispatch = useDispatch();
  const { status, data, error } = useQuery(
    ['menu', { menuId, lang: language }],
    fetchMenu,
    {
      onSuccess: (menu) => {
        dispatch(updateMenu({ menuId: menu.id }));
      },
    }
  );

  return {
    status,
    data,
    error,
  };
};

export default useMenu;
