import axios from 'axios';
import { AUTH_TOKEN_KEY } from 'static/localStorage/keys';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use(function (config) {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  config.headers.Authorization = token || process.env.REACT_APP_API_TOKEN;
  return config;
});

export { API };
