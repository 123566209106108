const s3ToCloudfont = (modelPath) => {
  if (!modelPath || typeof modelPath !== 'string') {
    console.log('No path provided.');
    return modelPath;
  }

  return `${modelPath?.replace(
    'https://diner.s3.eu-central-1.amazonaws.com/models',
    process.env.REACT_APP_CF_URL
  )}`;
};

export default s3ToCloudfont;
