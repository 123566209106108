import React from 'react';
import styled from 'styled-components';

const NavigationButton = ({
  background,
  position,
  text,
  icon,
  rightIcon,
  handleClick,
  borderRadius,
}) => {
  return (
    <NavigationButtonWrapper
      background={background}
      position={position}
      borderRadius={borderRadius}
      onClick={handleClick}
      rightIcon={rightIcon}
    >
      {icon}
      {text && <Text>{text}</Text>}
    </NavigationButtonWrapper>
  );
};

export default NavigationButton;

export const NavigationButtonWrapper = styled.div`
  cursor: pointer;
  z-index: 99;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '100%'};
  box-shadow: ${(props) =>
    props.background ? '0 13px 16px 0 rgba(0, 0, 0, 0.16)' : ''};
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: ${(props) => (props.rightIcon ? 'row-reverse' : 'row')};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  width: 3rem;
  height: 3rem;
`;

export const Text = styled.p`
  font-family: ${(props) => props.theme.fonts.barlow};
  color: ${(props) => props.theme.colors.background};
  font-size: 12px;
  font-weight: 800;
  line-height: 1.25;
  padding-left: 5px;
  padding-right: 5px;
`;
