import React from 'react';
import styled from 'styled-components';

import { GridItem } from './gridItem';

const Grid = ({ categories, onSelect }) => {
  return (
    <GridWrapper>
      <GridContainer>
        {categories.map((categoryData) => (
          <GridItem
            key={categoryData?.id}
            onClick={() => onSelect(categoryData)}
            // restaurantLogo={logo}
            category={categoryData}
          />
        ))}
      </GridContainer>
    </GridWrapper>
  );
};
export default Grid;

export const GridContainer = styled.div`
  display: grid;
  margin: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(150px, 1fr));
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  padding-bottom: 1rem;
`;
const GridWrapper = styled.div`
  width: 100%;
  z-index: 0;
  height: 100%;
`;
