import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { CallButton, Loader, Header, Error404 } from 'components';
import { logEvent } from 'analytics';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from 'hooks';
import ARTLogo from 'assets/png/art-logo-horizontal.png';

import { s3ToImgCDN } from 'utils';
import MenuButton from './MenuButton';

const Welcome = () => {
  const { restaurantId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const { status, data: restaurant, error } = useRestaurant({ restaurantId });

  useEffect(() => {
    if (restaurant) {
      document.title = restaurant.name;
    }
  }, [restaurant]);

  const handleClick = (menuId, language) => {
    const payload = { restaurant: restaurant.name, menu: menuId };

    if (restaurant.chain) payload.chain = restaurant.chain;

    logEvent({
      action: 'GO_TO_MENU',
      payload,
    });

    history.push(`${restaurantId}/menu/${menuId}/categories`, { language });
  };

  if (status === 'loading') {
    return <Loader />;
  }
  if (status === 'error ') {
    return (
      <span>
        Error:
        {error.message}
      </span>
    );
  }

  if (restaurant) {
    return (
      <RootWrapper className="w-full">
        <Header
          theme={restaurant.theme || 'DEFAULT'}
          rightIcon={
            <CallButton
              theme={restaurant?.theme || 'DEFAULT'}
              text={t('CALL')}
              phoneNumber={restaurant?.phoneNumber}
            />
          }
        />
        <div className="flex h-full flex-col justify-between items-center">
          <WelcomeContainer>
            <Logo src={s3ToImgCDN(restaurant.logo)} />
            <WelcomeTitle>{restaurant.name}</WelcomeTitle>
          </WelcomeContainer>

          <WelcomeInfo className="mb-8 text-center">
            {restaurant.menuIntro || t('WELCOME_MESSAGE')}
          </WelcomeInfo>
          {restaurant.menus.map((menu) => (
            <MenuButton
              key={menu.id}
              menu={menu}
              text={menu.name || t('MENU')}
              onClick={handleClick}
            />
          ))}
          <div className="flex flex-row justify-center items-center">
            <span>Powered by</span>
            <a
              href="https://artlabs.ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="w-40" src={ARTLogo} alt="ART Labs" />
            </a>
          </div>
        </div>
      </RootWrapper>
    );
  }

  return <Error404 />;
};

export default Welcome;

const RootWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 12vh;
  padding-bottom: 2rem;
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  justify-content: space-around;
  box-sizing: border-box;
  text-align: center;
`;

const Logo = styled.img`
  width: 152px;
  height: 152px;
  z-index: 1;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props) => props.theme.colors.background};
`;
export const WelcomeTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  z-index: 2;
  font-weight: 600;
  color: #000000;
`;

export const WelcomeInfo = styled.span`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 1rem;
  font-weight: 500;
  padding: 0 30px;
  z-index: 2;
  line-height: 1.19;
  color: ${(props) => props.theme.colors.text};
`;
