/* global MFS */
/* global $ */
import React, { useState } from 'react';
import CardInput from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { shallowEqual, useSelector } from 'react-redux';
import { useModali } from 'modali';
import { Button } from 'components';
import OTPForm from './OTPForm';

const RegisterCard = ({ onRegister }) => {
  const [card, setCard] = useState({
    cvc: '',
    expiryDate: '',
    focus: '',
    cardHolderName: '',
    rtaPan: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [otpModal, toggleOtpModal] = useModali({
    message: 'Please enter the code we sent to your phone',
    animated: true,
    large: true,
    centered: true,
    overlayClose: false,
    keyboardClose: false,
  });

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const token = useSelector((state) => state.masterpass.token);
  const reqRefNo = useSelector((state) => state.masterpass.reqRefNo);

  const handleInputFocus = (e) => {
    setCard({ ...card, focus: e.target.name });
    // toggleOtpModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCard({ ...card, [name]: value });
  };

  const onValidate = (transaction) => {
    toggleOtpModal();
    onRegister(transaction);
  };

  const mfsRegisterHandler = (status, response) => {
    setSubmitting(false);

    if (response?.responseCode === '5001') {
      toggleOtpModal();
    } else {
      console.log('Not 5001', response);
      alert(response?.responseDescription);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    setSubmitting(true);

    MFS.register($(document.forms.registerCard), mfsRegisterHandler);
  };

  // ISBANK		4508034508034509		12/2020	000	a
  // ISBANK		5406675406675403		12/2020	000	a

  return (
    <div>
      <CardInput
        cvc={card.cvc}
        expiry={card.expiryDate}
        focused={card.focus}
        name={card.cardHolderName}
        number={card.rtaPan}
      />
      <form
        name="registerCard"
        className="form-horizontal flex flex-col justify-center items-center pt-6"
        onSubmit={onSubmit}
      >
        <input
          type="text"
          name="accountAliasName"
          placeholder="Enter Card Name"
        />
        <input
          type="text"
          name="cardHolderName"
          placeholder="Holder Name"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <input
          type="text"
          name="rtaPan"
          placeholder="Card Number"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />

        <input
          type="text"
          name="expiryDate"
          placeholder="Valid Thru (YY / MM)"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <input
          type="text"
          name="cvc"
          placeholder="CVC"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />

        {/* MFS purchase and register parameters start */}
        <input type="hidden" name="msisdn" value={`90${user.phoneNumber}`} />
        <input type="hidden" name="token" value={token} />
        <input type="hidden" name="referenceNo" value={reqRefNo} />
        <input type="hidden" name="sendSmsLanguage" value="tur" />

        {/* -- MFS constant parameters start */}
        <input type="hidden" name="sendSms" value="N" />
        <input type="hidden" name="actionType" value="A" />
        <input type="hidden" name="clientIp" value="" />
        <input type="hidden" name="delinkReason" value="" />
        <input type="hidden" name="eActionType" value="A" />
        <input type="hidden" name="cardTypeFlag" value="05" />
        <input type="hidden" name="cpinFlag" value="Y" />
        <input type="hidden" name="defaultAccount" value="Y" />
        <input type="hidden" name="mmrpConfig" value="110010" />
        <input type="hidden" name="identityVerificationFlag" value="Y" />
        <input type="hidden" name="mobileAccountConfig" value="MWA" />
        <input type="hidden" name="timeZone" value="+03" />
        <input type="hidden" name="uiChannelType" value="6" />
        <div className="p-4">
          <div className="flex justify-start items-center mt-2">
            <input
              type="checkbox"
              checked={checkbox}
              onChange={() => setCheckbox(!checkbox)}
            />
            <span className="ml-4 text-sm">
              Masterpass kullanım koşullarını kabul ediyorum. &nbsp;
              <a
                target="blank"
                rel="noopener noreferrer"
                href="https://www.masterpassturkiye.com/TermsAndConditions.aspx"
              >
                <u>Detaylı bilgi için tıklayınız</u>
              </a>
            </span>
          </div>
        </div>
        <Button disabled={!checkbox} type="submit" loading={submitting}>
          Register Card
        </Button>

        <span className="m-4 text-sm">
          Kredi kartı bilgileriniz DINER tarafından saklanmamaktadır. Ödeme
          altyapısı MasterCard tarafından sağlanmaktadır.
          <a
            target="blank"
            rel="noopener noreferrer"
            href="https://www.mastercard.com/mc_us/wallet/learnmore/en/TR/"
          >
            <u>Detaylı bilgi için tıklayınız</u>
          </a>
        </span>
      </form>

      <OTPForm modal={otpModal} onValidate={onValidate} reqRefNo={reqRefNo} />
    </div>
  );
};

export default RegisterCard;
