import React from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Phone = ({ onSubmit }) => {
  const { handleSubmit, register, errors } = useForm();
  const isPending = useSelector((state) => state.auth.isPending, shallowEqual);
  const { t } = useTranslation();
  return (
    <motion.form
      key="phone"
      className="flex flex-col p-8"
      onSubmit={handleSubmit(onSubmit)}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
    >
      <div className="flex flex-col py-4 px-6 border-l-2 border-primary">
        <span className="text-xl">+90</span>
        <input
          ref={register({
            required: 'Required',
          })}
          id="phoneNumber"
          autoComplete="on"
          name="phoneNumber"
          type="tel"
          placeholder="5** *** ** **"
          maxLength={10}
          className="appearance-none rounded w-full py-2 px-3 text-3xl text-primary leading-tight active:outline-none focus:outline-none focus:shadow-outline mb-4"
        />
        <span className="text-primary text-opacity-75">
          {t(errors?.phoneNumber?.message.toUpperCase())}
        </span>
      </div>
      <button
        type="submit"
        disabled={isPending}
        className="w-full text-white text-2xl left-0 fixed bottom-0 z-20 py-6 px-10 flex justify-center items-center rounded-t-8 bg-primary"
      >
        {t('LOGIN.NEXT')} →
      </button>
    </motion.form>
  );
};

export default Phone;
