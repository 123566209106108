import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { RecommendedRestaurant, BackgroundGradient, Loader } from 'components';
import { fetchRecommendedRestaurants } from 'pages/home/Queries';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = (restaurant) => {
    history.push(`/${restaurant.slug}`);
  };
  const { status, data: recommendedRestaurants, error } = useQuery(
    ['recommendedrestaurants'],
    fetchRecommendedRestaurants
  );

  if (status === 'loading') {
    return <Loader />;
  }
  if (status === 'error ') {
    return (
      <span>
        Error:
        {error.message}
      </span>
    );
  }
  if (recommendedRestaurants) {
    return (
      <Wrapper>
        <BackgroundGradient position="absolute" />
        <Header1>{t('SELECTED_RESTAURANT_MESSAGE')}</Header1>{' '}
        <Container>
          {recommendedRestaurants.map((restaurant) => (
            <RecommendedRestaurant
              onClick={() => handleClick(restaurant)}
              key={restaurant.id}
              restaurant={restaurant}
            />
          ))}
        </Container>
      </Wrapper>
    );
  }
  return null;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: 10px;
  row-gap: 30px;
  margin: 40px 20px 10px 20px;
`;
const Header1 = styled.h1`
  font-family: ${(props) => props.theme.fonts.barlow};
  color: ${(props) => props.theme.colors.background};
  font-size: 2rem;
  z-index: 1;
  text-align: center;
  padding: 0 1.1rem;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export default Home;
