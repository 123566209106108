/* global MFS */
/* global $ */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useModali } from 'modali';
import { createOrder, payOrder, updateOrder } from 'store/slices/Order';
import { Button } from 'components';
import Cards from './Cards';
import OTPForm from './OTPForm';
import ThreeDSecure from './3DSecure';

const Purchase = ({ cart, onPurchase, onPurchaseError }) => {
  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [url3D, setUrl3D] = useState(false);
  const [otpModal, toggleOtpModal] = useModali({
    message: 'Please enter the code we sent to your phone',
    animated: true,
    large: true,
    centered: true,
  });

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const currentOrder = useSelector(
    (state) => state.order.current,
    shallowEqual
  );

  useEffect(() => {
    dispatch(createOrder(cart));
  }, [cart?.id]);

  const finalizeOrder = (token) => {
    dispatch(
      payOrder({
        orderNo: currentOrder.orderNo,
        token,
      })
    )
      .then(onPurchase)
      .catch(onPurchaseError);
  };

  const onValidate = (validation) => {
    toggleOtpModal();
    finalizeOrder(validation.token);

    console.log('onValidate', validation);
  };

  const purchaseResponseHandler = (statusCode, response) => {
    if (response.responseCode === '0000' || response.responseCode === '') {
      // Register Success
      if (response.token) {
        // console.log(`Token: ${response.token}`);
        finalizeOrder(response.token);
      }
    } else if (response.responseCode === '5001') {
      console.log('5001', response);
      toggleOtpModal();
    } else if (response.responseCode === '5002') {
      // $('#mpin-form').show();
      console.log('5002', response);
    } else if (response.responseCode === '5010') {
      // TODO: Ask 3D Secure
      console.log('5010', response);
      setUrl3D(
        `${response.url3D}&returnUrl=${'http://localhost:1337/order/pay'}`
      );
    } else if (response.responseCode === '5013') {
      console.log('5013', response);
      // $('#cvv-form').show();
    } else {
      alert(response.responseDescription);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.persist();

    setSubmitting(true);
    MFS.purchase($(e.nativeEvent.target), purchaseResponseHandler);
  };

  return (
    <>
      <Cards onSelected={setSelectedCard} />
      <form onSubmit={onSubmit}>
        <Button type="submit" loading={submitting}>
          Ödemeyi tamamla
        </Button>

        <input type="hidden" name="orderNo" value={currentOrder?.orderNo} />
        <input type="hidden" name="amount" value={currentOrder?.totalPrice} />
        <input
          type="hidden"
          name="token"
          value={currentOrder?.masterpass?.token}
        />
        <input
          type="hidden"
          name="referenceNo"
          value={currentOrder?.masterpass?.reqRefNo}
        />
        <input type="hidden" name="sendSmsLanguage" value="tur" />
        <input
          type="hidden"
          name="macroMerchantId"
          value={currentOrder?.masterpass?.merchantId}
        />
        <input
          type="hidden"
          name="listAccountName"
          value={selectedCard?.Name}
        />
        <input
          name="msisdn"
          type="hidden"
          placeholder={`90${user.phoneNumber}`}
          value={`90${user.phoneNumber}`}
          className="input-xlarge"
        />

        <input type="hidden" name="sendSms" value="N" />
        <input type="hidden" name="aav" value="aav" />
        <input type="hidden" name="clientIp" value="" />
        <input type="hidden" name="encCPin" value="0" />
        <input type="hidden" name="encPassword" value="" />
        <input type="hidden" name="sendSmsLanguage" value="tur" />
        <input type="hidden" name="sendSmsMerchant" value="Y" />
        <input type="hidden" name="password" value="" />
      </form>

      <OTPForm
        modal={otpModal}
        onValidate={onValidate}
        reqRefNo={currentOrder?.reqRefNo}
      />
      <ThreeDSecure
        src={url3D}
        onResult={(order) => dispatch(updateOrder(order))}
      />
    </>
  );
};

export default Purchase;
