import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { NavigationButton, Header } from 'components';
import { useTranslation } from 'react-i18next';
import { phoneLogin, phoneVerify } from 'store/slices/Authentication';
import { ReactComponent as UserIcon } from 'assets/svg/user.svg';
import { ReactComponent as BackIcon } from 'assets/svg/arrow-back.svg';

import { Phone, Verify } from 'pages/auth/components';

const Login = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const verificationId = useSelector(
    (state) => state.auth.verificationId,
    shallowEqual
  );

  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated,
    shallowEqual
  );

  useEffect(() => {
    if (isAuthenticated) {
      const redirect = new URLSearchParams(search).get('redirect');
      history.replace(redirect || '/');
    }
  }, [history, isAuthenticated, search]);

  const { t } = useTranslation();

  const onLogin = ({ phoneNumber }) => {
    dispatch(phoneLogin(`90${phoneNumber}`));
  };

  const onVerify = ({ verifyCode }) => {
    dispatch(phoneVerify({ verificationId, code: verifyCode }));
  };
  const handleBackButtonClick = useCallback(() => {
    history.goBack();
  }, []);

  return (
    <>
      <Header
        leftIcon={
          <NavigationButton
            borderRadius="100%"
            handleClick={handleBackButtonClick}
            icon={<BackIcon stroke="white" width={25} height={25} />}
          />
        }
        logo={<UserIcon width={60} height={60} />}
      >
        {!verificationId ? (
          <div className="py-6 flex flex-col justify-center items-center">
            <LoginTitle color="white">{t('LOGIN.TITLE')}</LoginTitle>
            <LoginInfo color="white">{t('LOGIN.SUBTITLE')}</LoginInfo>
          </div>
        ) : (
          <div className="py-6 flex flex-col justify-center items-center">
            <LoginTitle color="white">{t('VERIFY.TITLE')}</LoginTitle>
            <LoginInfo color="white">
              {t('CHECKOUT.ENTER_CODE_MESSAGE')}
            </LoginInfo>
          </div>
        )}
      </Header>
      <div
        style={{ paddingTop: '30vh' }}
        className="w-full flex justify-center"
      >
        {!verificationId ? (
          <Phone onSubmit={onLogin} />
        ) : (
          <Verify onSubmit={onVerify} />
        )}
      </div>
    </>
  );
};

export default Login;

export const LoginTitle = styled.span`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(props) => props.color};
`;

export const LoginSubtitle = styled.span`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => props.color};
`;

export const LoginInfo = styled.span`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.19;
  color: ${(props) => props.color};
`;
export const BackButton = styled.button`
  position: fixed;
  top: 5vw;
  left: 5vw;
  z-index: ${(props) => (props.modalExpanded ? 1 : -1)};
  width: 50px;
  height: 50px;
  border-radius: 100%;
  backdrop-filter: blur(15px) invert(30%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
