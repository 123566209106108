import React, { useEffect } from 'react';
import { ReactComponent as ErrorDrawing } from 'assets/svg/404.svg';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Error404 = () => {
  useEffect(() => {
    document.title = 404;
    return () => {
      document.title = 'Diner';
    };
  }, []);

  return (
    <div className="flex flex-col justify-start items-center w-screen h-screen">
      <ErrorDrawing />
      <Text>404</Text>
      <SubText>Aradığınız sayfa bulunamadı</SubText>
      <Link className="w-64" to="/">
        <Button>Anasayfaya Git</Button>
      </Link>
    </div>
  );
};

export default Error404;

const Text = styled.h1`
  font-family: Barlow;
  font-size: 7rem;
  font-weight: bolder;
  letter-spacing: 0px;
  color: #f65665;
`;
const SubText = styled.h2`
  font-family: Barlow;
  font-size: 1rem;
  letter-spacing: 0px;
  color: #646464;
`;

const Button = styled.button`
  background: transparent linear-gradient(100deg, #f84178 0%, #f27c43 100%) 0%
    0% no-repeat padding-box;
  border-radius: 36px;
  color: white;
  padding: 1.2rem;
  margin-top: 1rem;
  width: 100%;
`;
