import React, { useCallback } from 'react';
import { useMenu, useRestaurant } from 'hooks';
import {
  Tabs,
  Categories,
  Header,
  NavigationButton,
  RestaurantLogo,
  Error404,
  Loader,
} from 'components';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { animatedPageProps } from 'theme/animations';
import { ReactComponent as GridIcon } from 'assets/svg/grid-outline.svg';
import { useSelector } from 'react-redux';
import { ReactComponent as CartIcon } from 'assets/svg/cart.svg';

const Menu = () => {
  const { menuId, restaurantId } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const cartCount = useSelector((store) => store.cart?.items?.length);
  const availableOrderTypes = useSelector(
    (store) => store.cart?.availableOrderTypes
  );
  const { data: restaurant } = useRestaurant({ restaurantId });
  const { status, data: menu, error } = useMenu({
    menuId,
    language: state?.language,
  });

  const handleClickCart = useCallback(() => {
    history.push('/cart');
  }, [history]);

  const handleBackToCategory = () => {
    history.push(`/${restaurantId}/menu/${menuId}/categories`, {
      language: state?.language,
    });
  };

  if (status === 'error ') {
    return (
      <span>
        Error:
        {error.message}
      </span>
    );
  }
  if (status === 'loading') {
    return <Loader />;
  }
  if (menu) {
    return (
      <RootWrapper {...animatedPageProps}>
        <Header
          theme={restaurant?.theme}
          leftIcon={
            <NavigationButton
              icon={<GridIcon width={25} height={25} />}
              handleClick={handleBackToCategory}
            />
          }
          logo={<RestaurantLogo logo={restaurant?.logo} />}
          rightIcon={
            availableOrderTypes && (
              <NavigationButton
                text={cartCount}
                handleClick={handleClickCart}
                icon={<CartIcon width={25} height={25} />}
              />
            )
          }
        />

        <Tabs theme={restaurant?.theme} categories={menu?.categories} />
        <Categories categories={menu?.categories} />
      </RootWrapper>
    );
  }

  return <Error404 />;
};

export default Menu;
export const RootWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 17vh;
  width: 100%;
`;
