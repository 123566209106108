import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const defaultStyle = {
  flexShrink: '0',
  borderRadius: '20px',
  boxShadow: '0 10px 16px 0 rgba(112, 112, 112, 0.08)',
  objectFit: 'cover',
  width: '120px',
  height: '120px',
  minWidth: '120px',
};

// Use? https://github.com/Aljullu/react-lazy-load-image-component
const LazyImage = ({ placeholder, src, alt, style = defaultStyle }) => {
  return (
    <LazyLoadImage
      alt={alt}
      src={src}
      placeholderSrc={placeholder}
      useIntersectionObserver
      effect="blur"
      style={style}
      wrapperClassName="flexy"
    />
  );
};

export default LazyImage;
