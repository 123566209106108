/* eslint-disable no-undef */
import React, { useState } from 'react';
import Modali from 'modali';
import { Button } from 'components';
import Masterpass from 'assets/png/masterpass.png';

const OTPForm = ({ modal, reqRefNo, onValidate }) => {
  const [submitting, setSubmitting] = useState(false);

  function mfsResponseHandler(status, response) {
    setSubmitting(false);

    if (response.responseCode === '0000' || response.responseCode === '') {
      if (response.token) onValidate(response);
    } else if (response.responseCode === '5002') {
      // Ask MPIN
    } else {
      alert(response.responseDescription);
    }
  }

  return (
    <div>
      <Modali.Modal {...modal}>
        <div className="flex justify-between items-center px-4">
          <h1>BANKA DOĞRULAMASI</h1>
          <img className="h-6 w-30" src={Masterpass} alt="masterpass" />
        </div>
        <h2 className="px-8 pt-6 text-sm text-black text-opacity-75">
          Bankanız tarafından gönderilen tek kullanımlık şifrenizi giriniz.
        </h2>
        <form
          action=""
          method="POST"
          id="otp-form"
          className="bg-white shadow-md rounded px-8 pt-2 pb-8 mb-4 form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();

            setSubmitting(true);
            MFS.validateTransaction(
              $(e.nativeEvent.target),
              mfsResponseHandler
            );
          }}
        >
          <fieldset>
            <input
              name="validationCode"
              type="text"
              placeholder="XXXXX"
              inputMode="numeric"
              autoComplete="one-time-code"
              pattern="[0-9]*"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
            />

            <div className="control-group">
              <div className="controls">
                <Button
                  type="submit"
                  text="Send"
                  className="float-right"
                  loading={submitting}
                />
              </div>
            </div>
          </fieldset>

          <input type="hidden" name="referenceNo" value={reqRefNo} />
          <input type="hidden" name="sendSms" value="Y" />
          <input type="hidden" name="sendSmsLanguage" value="tur" />
          <input type="hidden" name="pinType" value="otp" />
        </form>
      </Modali.Modal>
    </div>
  );
};

export default OTPForm;
