import { fetchRestaurant } from 'queries';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { updateRestaurant } from 'store/slices/Cart';

const useRestaurant = ({ restaurantId }) => {
  const dispatch = useDispatch();
  const { status, data, error, refetch } = useQuery(
    ['restaurant', { restaurantId }],
    fetchRestaurant,
    {
      onSuccess: (restaurant) => {
        dispatch(
          updateRestaurant({
            availableOrderTypes: restaurant.availableOrderTypes,
            restaurantId: restaurant.id,
          })
        );
      },
    }
  );

  return {
    status,
    data,
    error,
    refetch,
  };
};

export default useRestaurant;
