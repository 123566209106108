import React, { useEffect } from 'react';

import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import Menu from 'pages/menu';
import Home from 'pages/home';
import Welcome from 'pages/welcome';
import Detail from 'pages/detail';
import Categories from 'pages/categories';
import Cart from 'pages/cart';
import Checkout from 'pages/checkout';
import CheckoutSuccess from 'pages/checkout/Success';
import CheckoutFailure from 'pages/checkout/Failure';
import Login from 'pages/auth/login';
import { AUTH_TOKEN_KEY, INAPP_BROWSER_KEY } from 'static/localStorage/keys';
import { AppleInstagram } from 'utils/userAgent';
import { ReactQueryDevtools } from 'react-query-devtools';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { verifyToken } from 'store/slices/Authentication';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(
    (state) => state.auth.isAuthenticated,
    shallowEqual
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={`/login?redirect=${rest.path}`} />
        )
      }
    />
  );
};

const App = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (AppleInstagram) {
      localStorage.setItem(INAPP_BROWSER_KEY, true);
    }

    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
      dispatch(verifyToken(token));
    }
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route key="home" exact path="/" component={Home} />
        <Route key="login" exact path="/login" component={Login} />
        <Route key="cart" exact path="/cart" component={Cart} />
        <ProtectedRoute
          key="checkout"
          exact
          path="/checkout"
          component={Checkout}
        />
        <ProtectedRoute
          key="checkout-success"
          exact
          path="/checkout/success"
          component={CheckoutSuccess}
        />
        <ProtectedRoute
          key="checkout-failure"
          exact
          path="/checkout/failure"
          component={CheckoutFailure}
        />
        <Route key="welcome" exact path="/:restaurantId" component={Welcome} />
        <Route
          key="categories"
          exact
          path="/:restaurantId/menu/:menuId/categories"
          component={Categories}
        />
        <Route
          key="list"
          exact
          path="/:restaurantId/menu/:menuId/list"
          component={Menu}
        />
        <Route
          key="detail"
          exact
          path="/:restaurantId/menu/:menuId/item/:foodItemId"
          component={Detail}
        />
      </Switch>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </>
  );
};

export default App;
