import React, { useCallback, useRef, useEffect } from 'react';
import { Header, NavigationButton } from 'components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as BackIcon } from 'assets/svg/arrow-back.svg';
import { ReactComponent as CartIcon } from 'assets/svg/cart.svg';
import { CartItem } from 'pages/cart/components';
import Currency from 'react-currency-formatter';

const Cart = () => {
  const history = useHistory();
  const itemContainerRef = useRef(null);
  const cart = useSelector((state) => state.cart);
  const cartTotal = useSelector((state) => {
    let totalPrice = 0;
    state.cart.items.forEach((item) => {
      totalPrice += item.quantity * item.price;
    });
    return totalPrice;
  });

  const goCheckout = () => {
    history.push('/checkout', { cart, cartTotal });
  };

  useEffect(() => {
    if (itemContainerRef.current) {
      window.scrollTo({
        behavior: 'smooth',
        top: itemContainerRef.current.offsetTop,
      });
    }
  }, []);

  const handleBackButtonClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <Header
        leftIcon={
          <NavigationButton
            borderRadius="100%"
            handleClick={handleBackButtonClick}
            icon={<BackIcon stroke="white" width={25} height={25} />}
          />
        }
        logo={<CartIcon width={60} height={60} />}
      >
        <div
          style={{ height: '15vh' }}
          className="flex flex-col justify-evenly mx-8 text-white items-between "
        >
          <div className="flex justify-between items-center">
            <h1 className="text-white text-2xl">Toplam Fiyat</h1>
            <p className="text-white text-opacity-75 text-xl">
              <Currency
                quantity={cartTotal / 100}
                // locale={i18n.language}
                // currency={currencyCode}
              />
            </p>
          </div>
          <div className="flex justify-between items-center">
            <h1 className="text-white text-2xl">Masa</h1>
            <p className="text-white text-opacity-75 text-xl">2 Numara</p>
          </div>
        </div>
      </Header>

      <div
        ref={itemContainerRef}
        style={{ paddingTop: '30vh' }}
        className="w-full min-h-screen h-full flex flex-col mx-8 pb-16 mb-24 "
      >
        {cart?.items?.map((item) => {
          return <CartItem item={item} key={item.cartItemGroupId} />;
        })}
        <div
          style={{ minHeight: '140px' }}
          className="rounded-6 shadow-basic text-secondary text-opacity-75 flex  justify-around border border-transparent "
        >
          <textarea
            className="w-full h-full p-3"
            id="cart-note"
            placeholder="Notlarını ekle"
          />
        </div>
      </div>
      <div
        onClick={goCheckout}
        className="w-full text-white text-2xl fixed bottom-0 z-20 py-6 px-10 flex justify-center items-center rounded-t-8 bg-primary"
      >
        Siparişini Tamamla
      </div>
    </>
    // <span className="text-lg">Checkout with</span>
    // <input
    //   type="image"
    //   alt="Checkout with Masterpass"
    //   src="https://masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg"
    //   onClick={goCheckout}
    // />
  );
};

export default Cart;
