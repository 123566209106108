import blurs from './blurs';
import colors from './colors';
import fonts from './fonts';

const defaultTheme = {
  colors: colors.DEFAULT,
  blurs: blurs.DEFAULT,
  fonts,
};

export { defaultTheme };
