import React from 'react';
import { ReactComponent as MinusIcon } from 'assets/svg/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import Currency from 'react-currency-formatter';
import { useSelector } from 'react-redux';

const PricingIndicator = ({
  handleMinus,
  price,
  quantity,
  currencyCode,
  handlePlus,
}) => {
  const availableOrderTypes = useSelector(
    (store) => store.cart?.availableOrderTypes
  );
  if (availableOrderTypes) {
    return (
      <div className="font-barlow text-3xl text-background font-bold items-center">
        <div className="flex justify-between">
          <button type="button" onClick={handleMinus}>
            <MinusIcon height={15} width={15} />
          </button>
          <div className="mx-4 text-white text-2xl">
            <Currency
              quantity={(price * quantity) / 100}
              currency={currencyCode}
            />
          </div>
          <button type="button" onClick={handlePlus}>
            <PlusIcon height={15} width={15} />
          </button>
        </div>
        <p className="text-center text-lg text-white text-opacity-75">
          {quantity} adet
        </p>
      </div>
    );
  }
  return (
    <div className="font-bold text-white text-3xl">
      <Currency quantity={(price * quantity) / 100} currency={currencyCode} />
    </div>
  );
};

export default PricingIndicator;
