import styled from 'styled-components';
import foodPattern from 'assets/svg/foodPattern.svg';

const BackgroundGradient = styled.div`
  position: ${(props) => props.position};
  background-image: url(${foodPattern});
  background-color: rgba(248, 65, 120, 1);
  width: 100vw;
  top: 0;
  height: 196px;
  z-index: ${(props) => props.zIndex};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 44px));
`;

export default BackgroundGradient;
