import React from 'react';
import styled from 'styled-components';
import { s3ToImgCDN } from 'utils';

const RestaurantLogo = ({ logo }) => {
  const cdnLogo = s3ToImgCDN(logo);

  return (
    <LogoWrapper>
      <Logo src={cdnLogo} />
    </LogoWrapper>
  );
};

export default RestaurantLogo;

const LogoWrapper = styled.div`
  display: flex;
  ${'' /* flex: 1; */}
  align-self: center;
  position: relative;
  justify-content: center;
`;

const Logo = styled.img`
  width: 62px;
  height: 62px;
  object-fit: contain;
  z-index: 3;
  border-radius: 10px;
  box-shadow: 0 13px 26px 0 rgba(0, 0, 0, 0.16);
`;
