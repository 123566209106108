import React from 'react';
import styled from 'styled-components';
import Category from './category';

// const steps = [
//   {
//     selector: '#arButton',
//     content:
//       'AR ikonunu gördüğün her yerde yemeği 3 boyutlu olarak görüntüleyebilirsin.',
//     style: {
//       backgroundColor: theme.colors.text,
//       color: 'white',
//       fontFamily: theme.fonts.condensed,
//       fontSize: '1.2rem',
//     },
//   },
// ];

// onRequestClose={() => {
//   localStorage.setItem(AR_INTRO_KEY, true);
//   logEvent({ category: 'MENU', action: 'AR_INTRO_COMPLETE' });
//   setIsVisited(true);
// }}

// const [isVisited, setIsVisited] = useState(
//   Boolean(localStorage.getItem(AR_INTRO_KEY))
// );

const Categories = ({ categories }) => {
  return (
    <CategoriesWrapper>
      {categories.map((categoryData) => {
        return <Category key={categoryData.id} categoryData={categoryData} />;
      })}
    </CategoriesWrapper>
  );
};

export default Categories;

const CategoriesWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 8vw;
`;
