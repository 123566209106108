import React from 'react';
import styled from 'styled-components';
import Currency from 'react-currency-formatter';
import { LazyImage } from 'components';
import { ReactComponent as ArIconImg } from 'assets/svg/ar-icon.svg';
import { getOptimizedImage } from 'utils';
import { motion } from 'framer-motion';

const MenuItem = ({ itemData, currencyCode, onClick }) => {
  const isImageAvailable = Boolean(itemData.image);
  const isArAvailable = Boolean(itemData.models && itemData.models.glb);
  const optimizedImage = getOptimizedImage(itemData?.imagePath, '&h=280');
  const tinyImage = getOptimizedImage(itemData?.imagePath, '&h=40');

  return (
    <MenuItemWrapper whileTap={{ scale: 0.95 }} onClick={onClick}>
      <ItemImageWrapper>
        {isImageAvailable && (
          <LazyImage
            placeholder={tinyImage}
            src={optimizedImage || itemData.image}
            alt={itemData.name}
          />
        )}
      </ItemImageWrapper>
      <ItemInfoWrapper>
        <FoodName>{itemData.name}</FoodName>
        <ItemDescription>{itemData.description}</ItemDescription>
        {itemData.price && (
          <FoodPrice>
            <Currency
              quantity={itemData.price / 100}
              // locale={i18n.language}
              currency={currencyCode}
            />
          </FoodPrice>
        )}
        {isArAvailable && (
          <ArButton id="arButton">
            AR
            <ArIcon />
          </ArButton>
        )}
      </ItemInfoWrapper>
    </MenuItemWrapper>
  );
};

export default MenuItem;

const MenuItemWrapper = styled(motion.div)`
  display: flex;
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  width: 100%;
  height: 140px;
  border-radius: 20px;
  box-shadow: 0 10px 16px 0 rgba(112, 112, 112, 0.16);
  background-color: ${(props) => props.theme.colors.background};
  margin: 5px 0px;
`;

const ItemImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ArButton = styled.div`
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  font-family: ${(props) => props.theme.fonts.condensed};
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.text};
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px 14px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 10px;
`;

const ArIcon = styled(ArIconImg)`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 30px;
  margin-left: 0.5rem;
  fill: ${(props) => props.theme.colors.text};
`;

const ItemInfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1rem;
`;

const GoToDetailText = styled.div`
  font-family: ${(props) => props.theme.fonts.condensed};
  font-size: 0.85rem;
  font-weight: 200;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.text};
  opacity: 0.5;
  margin-bottom: 4px;
`;
const ItemDescription = styled(GoToDetailText)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const FoodName = styled.span`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 17px;
  font-weight: 800;
  line-height: 1.21;
  color: ${(props) => props.theme.colors.text};
`;

const FoodPrice = styled.div`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.19;
  color: ${(props) => props.theme.colors.orange};
`;
