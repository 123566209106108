import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import {
  AnimateSharedLayout,
  motion,
  AnimatePresence,
  useCycle,
} from 'framer-motion';
import { useForm } from 'react-hook-form';
import colors from 'theme/colors';
import { useSelector } from 'react-redux';
import { ReactComponent as CartIcon } from 'assets/svg/cart.svg';

import PricingIndicator from './PricingIndicator';
import Modifier from './Modifier';

const Modal = ({
  price,
  currencyCode,
  modifiers,
  modalToggle,
  outerRef,
  theme,
  handleAddToCart,
}) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [quantity, setQuantity] = useState(1);
  const { register } = useForm();
  const availableOrderTypes = useSelector(
    (store) => store.cart?.availableOrderTypes
  );

  const handleMinus = useCallback(() => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }, [quantity]);

  const handlePlus = useCallback(() => {
    setQuantity(quantity + 1);
  }, [quantity]);

  const handleClose = (event) => {
    if (modifiers?.length > 0) {
      event.stopPropagation();
      toggleOpen();
      modalToggle(isOpen);
    }
  };

  useEffect(() => {
    const outerClickHandler = () => {
      if (isOpen) {
        toggleOpen();
        modalToggle(isOpen);
      }
    };
    outerRef.current.addEventListener('click', outerClickHandler);
    return () => {
      if (outerRef.current) {
        outerRef.current.removeEventListener('click', outerClickHandler);
      }
    };
  }, [isOpen, modalToggle, outerRef, toggleOpen]);

  return (
    <AnimateSharedLayout>
      <PriceWrapper theme={theme} isOpen={isOpen} layout className="flex-col">
        <motion.div
          layout
          initial="open"
          animate={isOpen ? 'open' : 'closed'}
          onClick={handleClose}
          className="flex flex-row justify-between w-full pt-4 pb-4 mb-4"
        >
          {modifiers?.length > 0 && (
            <button type="button">
              <svg width="23" height="23" viewBox="0 0 23 23">
                <motion.path
                  fill="transparent"
                  strokeWidth="3"
                  stroke="white"
                  strokeLinecap="round"
                  variants={{
                    closed: { d: 'M 2 2.5 L 20 2.5' },
                    open: { d: 'M 3 16.5 L 17 2.5' },
                  }}
                />
                <motion.path
                  fill="transparent"
                  strokeWidth="3"
                  stroke="white"
                  strokeLinecap="round"
                  variants={{
                    closed: { d: 'M 2 16.346 L 20 16.346' },
                    open: { d: 'M 3 2.5 L 17 16.346' },
                  }}
                />
              </svg>
            </button>
          )}
          {!modifiers?.length && availableOrderTypes && (
            <button
              className="text-white flex items-center justify-between active:outline-none  active:bg-transparent"
              type="button"
              onClick={() => handleAddToCart(quantity)}
            >
              <CartIcon width={25} height={25} className="mr-2" />
              Sepete Ekle
            </button>
          )}
          <PricingIndicator
            handleMinus={handleMinus}
            price={price}
            quantity={quantity}
            currencyCode={currencyCode}
            handlePlus={handlePlus}
          />
        </motion.div>

        <AnimatePresence>
          {isOpen &&
            modifiers?.map((modifier) => (
              <Modifier
                currencyCode={currencyCode}
                register={register}
                modifier={modifier}
                key={modifier.id}
              />
            ))}
        </AnimatePresence>
      </PriceWrapper>
    </AnimateSharedLayout>
  );
};

const PriceWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 20;
  height: ${(props) => (props.isOpen ? '75%' : '11vh')};
  box-sizing: border-box;
  padding: 0 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  background-color: ${(props) => colors[props.theme].primary};
  border-radius: 18px 18px 0px 0px;
`;

export default Modal;
