import React from 'react';
import { useForm } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Verify = ({ onSubmit }) => {
  const { handleSubmit, register, errors } = useForm();
  const isPending = useSelector((state) => state.auth.isPending, shallowEqual);
  const { t } = useTranslation();
  return (
    <motion.form
      key="verify"
      className="flex flex-col justify-start w-full items-center"
      onSubmit={handleSubmit(onSubmit)}
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
    >
      <input
        ref={register({
          required: 'Required',
        })}
        id="verifyCode"
        name="verifyCode"
        type="text"
        style={{
          letterSpacing: 40,
        }}
        placeholder="XXXX"
        maxLength={4}
        className="text-center sm:appearance-none border  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
      />
      {errors?.verifyCode?.message}
      <button
        type="submit"
        disabled={isPending}
        className="w-full text-white text-2xl left-0 fixed bottom-0 z-20 py-6 px-10 flex justify-center items-center rounded-t-8 bg-primary"
      >
        {t('LOGIN.VERIFY')} →
      </button>
    </motion.form>
  );
};

export default Verify;
