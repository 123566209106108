import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from 'assets/svg/close.svg';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow-up-outline.svg';

const Notification = ({ context, hasArrow, storageKey }) => {
  const [isVisible, setisVisible] = useState(
    Boolean(localStorage.getItem(storageKey) === 'true')
  );
  const handleClick = () => {
    localStorage.setItem(storageKey, false);
    setisVisible(false);
  };
  return (
    <NotificationContainer isVisible={isVisible}>
      <CloseButton onClick={handleClick}>
        <CrossIcon className="text-white" />
      </CloseButton>
      <ContextContainer>{context}</ContextContainer>
      {/* TODO: Import these properties from animations.js file */}
      <ArrowContainer
        hasArrow={hasArrow}
        animate={{ y: 10 }}
        transition={{
          yoyo: Infinity,
          duration: 1,
          ease: 'easeInOut',
          stiffness: 260,
          damping: 20,
        }}
      >
        <ArrowIcon />
      </ArrowContainer>
    </NotificationContainer>
  );
};

const NotificationContainer = styled.div`
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: fixed;
  padding: 0px 10px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.text};
  z-index: 100;
  align-items: center;
  justify-content: space-between;
`;
export const CloseButton = styled.button`
  height: 100%;
      border: none;
    background-color: rgba(0, 0, 0, 0);
}
`;
const ContextContainer = styled.p`
  color: white;
  font-family: ${(props) => props.theme.fonts.barlow};
  padding: 0px 5px 5px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.19;
  justify-content: center;
  text-align: center;
`;

const ArrowContainer = styled.div`
  display: ${(props) => (props.hasArrow ? 'block' : 'none')};
  fill: rgb(255, 255, 255);
  margin-right: 10px;
  width: 40px;
  height: 40px;
`;
export default Notification;
