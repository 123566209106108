/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';

const ThreeDSecure = ({ src, onResult }) => {
  useEffect(() => {
    const handler = (event) => {
      // const data = JSON.parse(event.data);
      console.log('Hello World?', event);
      onResult(event);
    };

    if (src) window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }, [src]); // empty array => run only once

  if (!src) return false;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <iframe width="400px" height="400px" title="3D Secure" src={src} />
    </div>
  );
};

export default ThreeDSecure;
