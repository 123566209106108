export default {
  DEFAULT: {
    secondary: '#111222',
    background: '#ffffff',
    text: '#111222',
    offWhite: '#fafafa',
    orange: '#f27c43',
    primary: '#f84178',
  },
  DARK: {
    secondary: '#111222',
    background: '#f84178',
    text: '#fafafa',
    offWhite: '#fafafa',
    orange: '#f27c43',
    primary: '#111222',
  },
  LIGHT: {
    secondary: '#111222',
    background: '#ffffff',
    text: '#111222',
    offWhite: '#fafafa',
    orange: '#f27c43',
    primary: '#f84178',
  },
  HAPPYMOONS: {
    secondary: '#111222',
    background: '#ffffff',
    text: '#111222',
    offWhite: '#fafafa',
    orange: '#f27c43',
    primary: '#E10B17',
  },
  GREEN: {
    secondary: '#111222',
    background: '#ffffff',
    text: '#111222',
    offWhite: '#fafafa',
    orange: '#f27c43',
    primary: '#6b8e23',
  },
  BEEVES: {
    secondary: '#111222',
    background: '#ffffff',
    text: '#111222',
    offWhite: '#fafafa',
    orange: '#f27c43',
    primary: '#a31927',
  },
};
