import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { setActiveCategory } from 'store/slices/Category';
import { useQueryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { logEvent } from 'analytics';
import { useRestaurant } from 'hooks';
import MenuItem from './menuItem';

const Category = ({ logo, categoryData }) => {
  const [scrollRef, inView] = useInView({
    threshold: 0.1,
    rootMargin: '196px 0px 0px 0px',
  });
  const categoryRef = useRef(null);
  const categoryState = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const history = useHistory();
  const { restaurantId, menuId } = useParams();

  const { data: restaurant } = useRestaurant({ restaurantId });

  // Either store language and menuId for useMenu
  // or
  // use query cache like below
  const cache = useQueryCache();
  const menu = cache.getQueryData((p) => p.queryKey[0] === 'menu');

  useEffect(() => {
    if (inView) {
      dispatch(
        setActiveCategory({
          id: categoryData.id,
          changeByScroll: true,
        })
      );
    }
  }, [inView, categoryData.id, dispatch]);

  useEffect(() => {
    if (categoryState.id === categoryData.id && !categoryState.changeByScroll) {
      const headerHeight = 196;
      const buffer = 30;
      const topOfElement =
        window.pageYOffset +
        categoryRef.current.getBoundingClientRect().top -
        headerHeight -
        buffer;

      window.scroll({
        top: topOfElement,
      });
    }
  }, [categoryState.id, categoryData.id, categoryState.changeByScroll]);

  const handleItemClick = (itemData) => {
    const eventPayload = { ...itemData, restaurant: restaurant.name };
    if (restaurant.chain) {
      eventPayload.chain = restaurant.chain;
    }

    logEvent({ action: 'GO_TO_FOOD_ITEM', payload: itemData });

    const { id } = itemData;
    history.push(`/${restaurantId}/menu/${menuId}/item/${id}`, {
      redirected: true,
      item: itemData,
    });
  };
  return (
    <CategoryWrapper ref={categoryRef}>
      <CategoryTitle ref={scrollRef}>{categoryData.name}</CategoryTitle>
      {categoryData.description && (
        <CategoryDescription>{categoryData.description}</CategoryDescription>
      )}
      <MenuItemsWrapper>
        {categoryData.items.map((itemData) => {
          if (!itemData.notAvailable) {
            return (
              <MenuItem
                key={itemData.id}
                categoryName={categoryData.name}
                itemData={itemData}
                logo={logo}
                currencyCode={menu?.currencyCode}
                onClick={() => handleItemClick(itemData)}
              />
            );
          }
          return null;
        })}
      </MenuItemsWrapper>
    </CategoryWrapper>
  );
};

export default Category;

const CategoryWrapper = styled.div`
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  scroll-margin: 302px;
`;

const MenuItemsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 9px;
`;
export const CategoryTitle = styled.div`
  opacity: 0.5;
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.text};
`;
export const CategoryDescription = styled.div`
  opacity: 0.4;
  font-family: ${(props) => props.theme.fonts.condensed};
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10px;
`;
