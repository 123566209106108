/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'utils/API';
import { AUTH_TOKEN_KEY } from 'static/localStorage/keys';

const PHONE_LOGIN = 'AUTH/PHONE_LOGIN';
const VERIFY_PHONE = 'AUTH/VERIFY_PHONE';
const VERIFY_TOKEN = 'AUTH/VERIFY_TOKEN';

export const phoneLogin = createAsyncThunk(PHONE_LOGIN, async (phoneNumber) => {
  const response = await API.post('/auth/login/phone', { phoneNumber });
  return response.data;
});

export const phoneVerify = createAsyncThunk(
  VERIFY_PHONE,
  async ({ verificationId, code }) => {
    const response = await API.post('/auth/verify/phone', {
      verificationId,
      code,
    });
    return response.data;
  }
);

export const verifyToken = createAsyncThunk(VERIFY_TOKEN, async (token) => {
  const response = await API.get('/auth/verify/token', {
    headers: { Authorization: token },
  });

  return response.data;
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: '',
    isAuthenticated: false,
    isPending: false,
    user: null,
    verificationId: null,
  },
  extraReducers: {
    // LOGIN
    [phoneLogin.pending]: (state) => {
      state.isPending = true;
    },
    [phoneLogin.fulfilled]: (state, action) => {
      state.isPending = false;
      state.verificationId = action.payload.verificationId;
    },
    [phoneLogin.rejected]: (state) => {
      state.isAuthenticated = false;
      state.isPending = false;
    },
    // OTP VERIFY
    [phoneVerify.pending]: (state) => {
      state.isPending = true;
    },
    [phoneVerify.fulfilled]: (state, action) => {
      state.isPending = false;
      state.isAuthenticated = true;
      state.verificationId = null;
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem(AUTH_TOKEN_KEY, action.payload.token);
    },
    [phoneVerify.rejected]: (state) => {
      state.isAuthenticated = false;
      state.isPending = false;
    },
    // TOKEN VERIFY
    // [verifyToken.pending]: (state) => {
    //   state.isPending = true
    // },
    [verifyToken.fulfilled]: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      // sessionStorage.setItem(AUTH_USER_KEY, JSON.stringify(action.payload));
    },
    [verifyToken.rejected]: (state) => {
      state.isAuthenticated = false;
      state.isPending = false;
    },
  },
});

export default authSlice;
