/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect } from 'react';
import { NavigationButton, Header } from 'components';
import { Redirect, useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from 'assets/svg/arrow-back.svg';
import MasterpassLogo from 'assets/png/masterpass.png';
import Currency from 'react-currency-formatter';
import { useDispatch, useSelector } from 'react-redux';
import { initialize, setStatus, STATUSES } from 'store/slices/Masterpass';
import { ReactComponent as SpinIcon } from 'assets/svg/spin.svg';
import CheckMasterPass from './CheckMasterPass';
import RegisterCard from './RegisterCard';
import Purchase from './Purchase';
import LinkToClient from './LinkToClient';

const Masterpass = ({ cart }) => {
  const dispatch = useDispatch();
  const isMasterpassInitialized = useSelector(
    (state) => state.masterpass.initialized
  );
  const status = useSelector((state) => state.masterpass.status);
  const history = useHistory();
  useEffect(() => {
    if (!isMasterpassInitialized) {
      dispatch(initialize());
    }
  }, [isMasterpassInitialized]);

  if (!isMasterpassInitialized) {
    return <SpinIcon fill="pink" stroke="pink" />;
  }

  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{ paddingTop: '30vh' }}
    >
      {status === null && <CheckMasterPass />}
      {status === STATUSES.NOT_LINKED && (
        <LinkToClient onLinked={() => dispatch(setStatus(STATUSES.LINKED))} />
      )}
      {(status === STATUSES.NO_ACCOUNT ||
        status === STATUSES.REGISTER_CARD) && (
        <RegisterCard onRegister={() => dispatch(setStatus(STATUSES.LINKED))} />
      )}
      {status === STATUSES.LINKED && (
        <Purchase
          cart={cart}
          onPurchaseError={() => history.replace('/checkout/failure')}
          onPurchase={() => history.replace('/checkout/success')}
        />
      )}
    </div>
  );
};

const Checkout = () => {
  const cart = useSelector((state) => state.cart);
  const status = useSelector((state) => state.masterpass.status);
  // Use a memoized cartTotal selector somehow a library or sth??
  const cartTotal = useSelector((state) => {
    let totalPrice = 0;
    state.cart.items.forEach((item) => {
      totalPrice += item.quantity * item.price;
    });
    return totalPrice;
  });

  const history = useHistory();

  const handleBackButtonClick = useCallback(() => {
    history.goBack();
  }, [history]);

  if (!cart.items.length) return <Redirect to="/cart" />;

  return (
    <div className="flex flex-col w-full">
      <div
        style={{
          opacity: status === STATUSES.NOT_LINKED ? '0.4' : '1',
          filter: status === STATUSES.NOT_LINKED ? 'blur(5px)' : 'initial',
        }}
      >
        <Header
          leftIcon={
            <NavigationButton
              borderRadius="100%"
              handleClick={handleBackButtonClick}
              icon={<BackIcon stroke="white" width={25} height={25} />}
            />
          }
          logo={
            <img className="h-12 w-56 " src={MasterpassLogo} alt="masterpass" />
          }
        >
          <div
            style={{ height: '15vh' }}
            className="flex flex-col justify-evenly mx-8 text-white items-between "
          >
            <div className="flex justify-between items-center">
              <h1 className="text-white text-2xl">Toplam Fiyat</h1>
              <p className="text-white text-opacity-75 text-xl">
                <Currency
                  quantity={cartTotal / 100}
                  // locale={i18n.language}
                  // currency={currencyCode}
                />
              </p>
            </div>
            <div className="flex justify-between items-center">
              <h1 className="text-white text-2xl">Masa</h1>
              <p className="text-white text-opacity-75 text-xl">2 Numara</p>
            </div>
          </div>
        </Header>
      </div>
      <Masterpass cart={cart} />
    </div>
  );
};

export default Checkout;
