import React from 'react';
import styled from 'styled-components';
import { LazyImage } from 'components';
import { getOptimizedImage } from 'utils';

const RecommendedRestaurant = ({ restaurant, onClick }) => {
  const { logo, name, district, imagePath } = restaurant;
  const optimizedImage = getOptimizedImage(imagePath, '&h=240');

  return (
    <RecommendedRestaurantContainer onClick={onClick}>
      <ImageContainer>
        <LazyImage src={logo} alt={name} placeholder={optimizedImage || logo} />
        <Header2>{name}</Header2>
        <District>{district}</District>
      </ImageContainer>
    </RecommendedRestaurantContainer>
  );
};

const RecommendedRestaurantContainer = styled.div`
  border-radius: 20px;
  padding: 5px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.text};
  box-shadow: 0 10px 16px 0 rgba(112, 112, 112, 0.16);
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    margin-top: -15px;
    z-index: 1;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    box-shadow: 0 10px 16px 0 rgba(112, 112, 112, 0.16);
  }
`;

const Header2 = styled.h2`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 1.5rem;
  text-align: center;
  padding: 0px 3px;
  margin-bottom: 5px;
`;

const District = styled.span`
  font-family: ${(props) => props.theme.fonts.condensed};
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
`;

export default RecommendedRestaurant;
