/* eslint-disable no-undef */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { animatedPageProps } from 'theme/animations';
import { ArView, Loader, Error404 } from 'components';
import { ReactComponent as BackIcon } from 'assets/svg/arrow-back.svg';
import { fetchFoodItem } from 'pages/detail/Queries';
import { useQuery } from 'react-query';
import { Modal, DimensionSwitcher } from 'pages/detail/components';
import { useMenu, useRestaurant } from 'hooks';
import colors from 'theme/colors';
import { useDispatch } from 'react-redux';
import { add } from 'store/slices/Cart';
import { FEEDBACK_KEY } from 'static/localStorage/keys';

const Detail = () => {
  const { foodItemId, restaurantId, menuId } = useParams();
  const ref = useRef(null);
  const { state } = useLocation();
  const [modalExpanded, setModalExpanded] = useState(true);
  const [switchValue, setSwitchValue] = useState(false);
  const shownFeedback = sessionStorage.getItem(FEEDBACK_KEY);

  const dispatch = useDispatch();

  const { data: restaurant } = useRestaurant({ restaurantId });
  const { data: menu } = useMenu({ menuId });

  const history = useHistory();

  const handleBackButtonClick = () => {
    if (state?.redirected) {
      history.goBack();
      try {
        if (appzi && !shownFeedback && switchValue === true) {
          const appziId = Object.keys(appzi.Ot)[0];
          setTimeout(() => {
            appzi.openWidget(appziId);
            sessionStorage.setItem(FEEDBACK_KEY, true);
          }, 1000);
        }
      } catch (_err) {
        console.log('something went wroing', _err);
      }
    } else {
      history.push(`/${restaurantId}/menu/${menuId}/list`);
    }
  };

  const handleModalToggle = (isModalExpanded) => {
    setModalExpanded(isModalExpanded);
  };

  const { status, data: foodItem, error } = useQuery(
    ['fooditems', { foodItemId }],
    fetchFoodItem,
    { retry: 1, initialData: state?.item }
  );

  const handleAddToCart = (quantity) => {
    dispatch(add({ ...foodItem, quantity }));
  };

  if (status === 'loading') {
    return <Loader />;
  }
  if (status === 'error ') {
    return (
      <span>
        Error:
        {error.message}
      </span>
    );
  }

  if (foodItem) {
    return (
      <>
        <RootWrapper
          modalExpanded={modalExpanded}
          {...animatedPageProps}
          initial="initial"
          ref={ref}
          animate="in"
          exit="out"
        >
          <BackButton
            modalExpanded={modalExpanded}
            onClick={handleBackButtonClick}
          >
            <BackIcon
              stroke={colors[restaurant?.theme || 'DEFAULT']?.offWhite}
              width={25}
              height={25}
            />
          </BackButton>
          {foodItem.models?.usdz && foodItem.models?.glb && (
            <DimensionSwitcher
              switchValue={switchValue}
              setSwitchValue={setSwitchValue}
            />
          )}

          <ArView
            modalExpanded={modalExpanded}
            key="arButton"
            isToggled={switchValue}
            models={foodItem?.models}
            name={foodItem?.name}
            image={foodItem?.image && foodItem?.imagePath}
          />
          <ItemInfoWrapper noMedia={!(foodItem?.image && foodItem?.models)}>
            <DetailTitle>{foodItem?.name}</DetailTitle>
            <DetailDescription>{foodItem?.description}</DetailDescription>
          </ItemInfoWrapper>
        </RootWrapper>
        {foodItem.price > 0 && (
          <Modal
            handleAddToCart={handleAddToCart}
            theme={restaurant?.theme || 'DEFAULT'}
            outerRef={ref}
            modalToggle={handleModalToggle}
            modifiers={foodItem?.modifiers}
            price={foodItem?.price}
            currencyCode={menu?.currencyCode}
          />
        )}
      </>
    );
  }
  return <Error404 />;
};

export default Detail;

const RootWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  &:before {
    content: '';
    backdrop-filter: ${(props) =>
      props.modalExpanded ? 'blur(0px)' : 'blur(5px)'};
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
  }
`;

const ItemInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  position: ${(props) => (props.noMedia ? '' : 'absolute')};
  top: ${(props) => (props.noMedia ? '' : '60%')};
  padding-bottom: 13vh;
  flex: 1;
  z-index: -1;
  flex-direction: column;
  @media (min-width: 600px) {
    align-items: center;
  }
  & > div {
    margin-top: 20px;
  }
  padding: 0 8vw;
  box-sizing: border-box;

  overflow: hidden;
`;

const DetailTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 22px;
  font-weight: 800;
  line-height: 1.18;
  color: ${(props) => props.theme.colors.text};
`;

const DetailDescription = styled.div`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.21;
  margin-bottom: 13vh;
  color: ${(props) => props.theme.colors.text};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
  & > div {
    margin-right: 40px;
  }
`;

export const BackButton = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.modalExpanded ? 1 : -1)};
  width: 50px;
  margin: 1.5rem;
  height: 50px;
  border-radius: 100%;
  backdrop-filter: blur(15px) invert(30%);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
