import React from 'react'
import styled from 'styled-components'

const Tag = ({ name }) => {
  return (
    <TagWrapper>
      <TagTitle>{name}</TagTitle>
    </TagWrapper>
  )
}

export default Tag

export const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  height: 35px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.orange};
`
export const TagTitle = styled.div`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 14px;
  font-weight: 800;
  line-height: 1.21;
  color: ${(props) => props.theme.colors.background};
`
