import React from 'react';
import { ReactComponent as SpinIcon } from 'assets/svg/spin.svg';

const baseClasses =
  'bg-gradient-to-bl from-pink-500 via-red-500 to-yellow-500 py-2 px-4 rounded transition-all inline-flex items-center justify-center border-border-transparent text-base text-white font-medium ';
const Button = ({
  text,
  children,
  loading,
  disabled,
  onClick,
  className,
  ...props
}) => {
  let _className = baseClasses + className;

  if (loading || disabled) {
    _className += ' opacity-50 cursor-not-allowed';
  }

  return (
    <button
      type="button"
      disabled={loading || disabled}
      className={_className}
      onClick={onClick}
      {...props}
    >
      {loading && <SpinIcon />}
      {text || children}
    </button>
  );
};

export default Button;
