import React from 'react';
import styled from 'styled-components';
import PacmanLoader from 'react-spinners/PacmanLoader';

const Loader = () => {
  return (
    <LoaderWrapper>
      <PacmanLoader color="white" loading margin={0} size={20} />
    </LoaderWrapper>
  );
};
const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
  & > div {
    margin-right: 40px;
  }
`;

export default Loader;
