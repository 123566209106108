import React from 'react';
import { motion } from 'framer-motion';
import Currency from 'react-currency-formatter';

const Modifier = ({ modifier, register, currencyCode }) => {
  return (
    <motion.form
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="font-barlow w-full mb-4"
    >
      <h4 className="font-bold text-background text-2xl">{modifier?.name}</h4>
      <p className="font-normal text-background mb-2 text-opacity-75">
        {modifier.description}
      </p>
      {modifier?.items?.map((item) => {
        return (
          <div key={item.id} className="mb-4 shadow-basic">
            <label htmlFor={item.id}>
              <div className="flex items-center text-secondary justify-between rounded-lg w-full bg-background h-12 p-2">
                <p className="font-bold text-opacity-75 text-secondary">
                  {item.name}
                </p>
                {item.price > 0 && (
                  <p className="font-light  text-secondary text-opacity-75">
                    +
                    <Currency
                      quantity={item.price / 100}
                      currency={currencyCode}
                    />
                  </p>
                )}
              </div>
              <input
                id={item.id}
                className="hidden"
                name={`${modifier.name}.${item.name}`}
                ref={register}
                type="checkbox"
              />
            </label>
          </div>
        );
      })}
    </motion.form>
  );
};

export default Modifier;
