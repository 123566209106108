import React, { useCallback } from 'react';
import { LazyImage } from 'components';
import Currency from 'react-currency-formatter';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { useDispatch } from 'react-redux';
import { remove } from 'store/slices/Cart';

// TODO: Set optimized image
const CartItem = ({ item }) => {
  const dispatch = useDispatch();

  const handleRemoveCartFromItem = useCallback(() => {
    dispatch(remove(item.cartItemGroupId));
  }, [dispatch, item]);
  return (
    <div
      style={{ minHeight: '140px' }}
      className="rounded-6 mb-3 shadow-2xl  flex p-3 justify-around "
    >
      {item.image && (
        <div>
          <LazyImage alt={item.name} src={item.image} />
        </div>
      )}

      <div className="flex-col flex justify-between items-start ml-3 w-full">
        <div className="flex justify-between items-center w-full">
          <h4 className="text-secondary text-lg font-bold"> {item.name}</h4>

          <CloseIcon
            onClick={handleRemoveCartFromItem}
            className="text-secondary p-2"
          />
        </div>

        <div className="flex justify-between items-center w-full">
          <span className="tracking-wider text-orange  text-md text-opacity-75">
            {item.quantity} x
            <Currency
              quantity={item.price / 100}
              // locale={i18n.language}
              // currency={currencyCode}
            />
          </span>
          <button
            type="button"
            className="w-20 bg-primary p-1 text-white rounded"
          >
            Düzenle
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
