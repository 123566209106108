/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SpinIcon } from 'assets/svg/spin.svg';
import { STATUSES, fetchToken, setStatus } from 'store/slices/Masterpass';

const handleMasterpassStatus = (status, response, callback) => {
  /* 
  1. MasterPass hesabı bulunmayan kullanıcı (000000XXXXXXXXXX)
  2. MasterPass hesabı olup üye işyerinde hiç işlem yapmamış kullanıcı (X1100XXXXXXXXXXX)
  3. MasterPass hesabı olup işyerinde hesabını ilşkilendirmiş olan kullanıcı (X11100XXXXXXXXXX)
  */

  if (response.responseCode === '0000' || response.responseCode === '') {
    const { accountStatus } = response;

    if (accountStatus.startsWith(STATUSES.NO_ACCOUNT)) {
      console.info('No account => purchaseAndRegister');
      callback(STATUSES.NO_ACCOUNT);
    }

    if (accountStatus.substring(1).startsWith(STATUSES.NOT_LINKED)) {
      console.info('Not linked => linkCardToClient');
      callback(STATUSES.NOT_LINKED);
    }

    if (accountStatus.substring(1).startsWith(STATUSES.LINKED)) {
      console.info('Linked => listCards');
      callback(STATUSES.LINKED);
    }
  } else {
    // TODO: Handle error
    return console.warn('Err', response.responseDescription);
  }

  return false;
};

const CheckMasterPass = () => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const token = useSelector((state) => state.masterpass.token);
  const reqRefNo = useSelector((state) => state.masterpass.reqRefNo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      MFS.checkMasterPass($(document.forms.checkMP), (status, response) =>
        handleMasterpassStatus(status, response, (_status) =>
          dispatch(setStatus(_status))
        )
      );
    } else {
      dispatch(fetchToken());
    }
  }, [token]);

  if (!user) {
    return <div>No User</div>;
  }

  return (
    <>
      <SpinIcon fill="pink" stroke="pink" />
      <form name="checkMP" hidden>
        <input
          hidden
          name="userId"
          type="text"
          placeholder={`90${user?.phoneNumber}`}
          disabled
          defaultValue={`90${user?.phoneNumber}`}
          className="input-xlarge"
        />
        <input type="hidden" name="token" disabled defaultValue={token} />
        <input
          type="hidden"
          disabled
          name="referenceNo"
          defaultValue={reqRefNo}
        />
        <input type="hidden" name="sendSmsLanguage" value="tur" />
        <input type="hidden" name="sendSms" value="false" />
      </form>
    </>
  );
};

export default CheckMasterPass;
