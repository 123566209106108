const pageTransitionVariants = {
  initial: {
    opacity: 0.8,
    scale: 1.1,
    // x: 500,
  },
  in: {
    opacity: 1,
    scale: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    scale: 0.8,
    // x: -500,
  },
};

const animatedPageProps = {
  initial: 'initial',
  animate: 'in',
  exit: 'out',
  transition: { duration: 0.25, type: 'tween' },
  variants: pageTransitionVariants,
};

export { pageTransitionVariants, animatedPageProps };
