import React from 'react';
import { render } from 'react-dom';
import './index.css';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import './styles/tailwind.css';
import * as Sentry from '@sentry/browser';
import { defaultTheme } from 'theme/theme';
import {
  QueryCache,
  ReactQueryConfigProvider,
  ReactQueryCacheProvider,
} from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import './i18n/index';
import App from './App';
import { store, persistor } from './store';

if (process.env.REACT_APP_ENV === 'production') {
  if (process.env.REACT_APP_SENTRY_RELEASE) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      environment: process.env.REACT_APP_ENV,
    });
  }
}

const reactQueryConfig = {
  queries: { refetchOnWindowFocus: false, staleTime: Infinity, retry: false },
};
const queryCache = new QueryCache();

render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={defaultTheme}>
          <ReactQueryConfigProvider config={reactQueryConfig}>
            <ReactQueryCacheProvider queryCache={queryCache}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ReactQueryCacheProvider>
          </ReactQueryConfigProvider>
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
