export default {
  light: `background-color: rgba(255,255,255,0.6);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);`,
  dark: `background-color: rgba(0,0,0,0.65);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);`,
  xDark: `background-color: rgba(0,0,0,0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);`,
};
