import React, { useCallback } from 'react';
import styled from 'styled-components';
import { animatedPageProps } from 'theme/animations';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  Header,
  Loader,
  Grid,
  RestaurantLogo,
  NavigationButton,
  Error404,
} from 'components';
import { ReactComponent as BackIcon } from 'assets/svg/arrow-back.svg';
import { useMenu, useRestaurant } from 'hooks';
import { logEvent } from 'analytics';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCategory } from 'store/slices/Category';
import { ReactComponent as CartIcon } from 'assets/svg/cart.svg';

const Categories = () => {
  const { menuId, restaurantId } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const cartCount = useSelector((store) => store.cart?.items?.length);
  const availableOrderTypes = useSelector(
    (store) => store.cart?.availableOrderTypes
  );

  const { status, data, error } = useMenu({
    menuId,
    language: state?.language,
  });

  const { data: restaurant } = useRestaurant({ restaurantId });

  const handleBackButtonClick = () => {
    history.push(`/${restaurantId}`);
  };
  const handleClickCart = useCallback(() => {
    history.push('/cart');
  }, [history]);

  const handleCategoryClick = (category) => {
    logEvent({
      action: 'GO_TO_MENU_CATEGORY',
      payload: category,
    });

    dispatch(setActiveCategory({ id: category.id, changeByScroll: false }));
    history.push(`/${restaurantId}/menu/${menuId}/list`, {
      language: state?.language,
    });
  };

  if (status === 'loading') {
    return <Loader />;
  }

  if (status === 'error ') {
    return (
      <span>
        Error:
        {error.message}
      </span>
    );
  }

  if (data) {
    return (
      <RootWrapper {...animatedPageProps}>
        <Header
          theme={restaurant?.theme}
          logo={<RestaurantLogo logo={restaurant?.logo} />}
          leftIcon={
            <NavigationButton
              borderRadius="100%"
              handleClick={handleBackButtonClick}
              icon={<BackIcon stroke="white" width={25} height={25} />}
            />
          }
          rightIcon={
            availableOrderTypes && (
              <NavigationButton
                text={cartCount}
                handleClick={handleClickCart}
                icon={<CartIcon width={25} height={25} />}
              />
            )
          }
        />
        <Grid categories={data?.categories} onSelect={handleCategoryClick} />
      </RootWrapper>
    );
  }

  return <Error404 />;
};

export const ToggleWrapper = styled.div`
  position: fixed;
  top: 100px;
  z-index: 3;
  right: 70px;
`;
export const RootWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 17vh;
  width: 100%;
`;

export default Categories;
