import React from 'react';
import { Switch } from '@headlessui/react';

const DimensionSwitcher = ({ switchValue, setSwitchValue }) => {
  return (
    <div className="fixed top-0 right-0 z-50 flex items-center justify-center p-6">
      <Switch.Group as="div" className="flex items-center">
        <Switch
          as="button"
          checked={switchValue}
          onChange={setSwitchValue}
          className="bg-gray-200 border border-opacity-50 relative inline-flex items-center justify-center h-14 transition-colors duration-200 ease-in-out rounded-full cursor-pointer w-28 focus:outline-none focus:shadow-outline"
        >
          {({ checked }) => (
            <>
              <span className="flex p-2 justify-between items-center">
                <span
                  className={`${
                    checked
                      ? 'bg-white text-secondary text-opacity-25'
                      : 'bg-secondary text-white'
                  } flex justify-center items-center w-10 mr-2 h-6 transition duration-200 ease-in-out transform rounded-full`}
                >
                  2D
                </span>
                <span
                  className={`${
                    checked
                      ? 'bg-secondary text-white'
                      : 'bg-white text-secondary text-opacity-25'
                  } flex justify-center items-center w-10  h-6 transition duration-200 ease-in-out transform rounded-full`}
                >
                  3D
                </span>
              </span>
            </>
          )}
        </Switch>
      </Switch.Group>
    </div>
  );
};

export default DimensionSwitcher;
