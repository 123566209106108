/* global MFS */
/* global $ */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SpinIcon } from 'assets/svg/spin.svg';
import { ReactComponent as AddCardIcon } from 'assets/svg/addCard.svg';
import { STATUSES, setStatus } from 'store/slices/Masterpass';

const Cards = ({ onSelected }) => {
  const [cards, setCards] = useState([]);
  const cardsRef = useRef(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [fetching, setFetching] = useState(true);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const token = useSelector((state) => state.masterpass.token);

  function listCardsResponseHandler(statusCode, response) {
    if (response.responseCode !== '0000' && response.responseCode !== '') {
      console.log(response.responseDescription);
      console.info('TransactionID->', response.transactionId);
      return;
    }

    setCards(response.cards);
    setSelectedCard(response.cards?.[0]);
    onSelected(response.cards?.[0]);
    setFetching(false);
  }

  useEffect(() => {
    MFS.listCards(`90${user.phoneNumber}`, token, listCardsResponseHandler);
  }, [user.phoneNumber]);

  useEffect(() => {
    if (cardsRef.current) {
      window.scrollTo({
        behavior: 'smooth',
        top: cardsRef.current.offsetTop,
      });
    }
  }, []);

  return (
    <>
      <div ref={cardsRef} className="flex flex-col w-full px-8 pb-24">
        {cards.map((card) => (
          <div
            key={card.UniqueId}
            className="rounded-6 mb-3 shadow-2xl  flex p-3 justify-around h-32"
            onClick={() => setSelectedCard(card)}
          >
            <label
              className="inline-flex items-center mt-3 w-full"
              htmlFor={card.UniqueId}
            >
              <input
                name={card.UniqueId}
                type="radio"
                className="form-radio h-5 w-5 text-gray-600"
                checked={card.UniqueId === selectedCard?.UniqueId}
              />

              <span className="ml-2 text-gray-700">
                {card.Name}
                <br />
                {card.Value1}
              </span>
            </label>
          </div>
        ))}
        <div className="w-full p-6 bg-black flex rounded-8 shadow-2 items-center">
          {fetching ? (
            <SpinIcon fill="pink" stroke="pink" />
          ) : (
            <>
              <AddCardIcon />
              <button
                type="button"
                className="text-white flex-1"
                onClick={() => dispatch(setStatus(STATUSES.REGISTER_CARD))}
              >
                YENI KART EKLE
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Cards;
