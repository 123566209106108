/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import cloudPattern from 'assets/svg/endless-clouds.svg';
import { Redirect } from 'react-router-dom';

const Failure = () => {
  const currentOrder = useSelector(
    (state) => state.order.current,
    shallowEqual
  );

  if (!currentOrder) {
    return <Redirect to="/cart" />;
  }

  return (
    <Wrapper>
      <HeaderBackground className="flex justify-center content-center flex-wrap">
        <Header color="white" className="shadow-md">
          We got your order but payment is not successful
        </Header>
      </HeaderBackground>
      <Container>
        <div className="flex flex-col justify-center items-center mb-4">
          <span className="text-xl">Order Number: {currentOrder.orderNo}</span>
          <span />
          {/* <span className="text-xl">
            Amount: {currentOrder.totalPrice / 100}₺
          </span> */}
        </div>
      </Container>
    </Wrapper>
  );
};

const HeaderBackground = styled.div`
  position: ${(props) => props.position};
  background-image: url(${cloudPattern});
  background-color: tomato;
  width: 100vw;
  top: 0;
  height: 196px;
  z-index: ${(props) => props.zIndex};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 44px));
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 40px 20px 10px 20px;
`;

export const Header = styled.div`
  font-family: ${(props) => props.theme.fonts.barlow};
  font-size: 2rem;
  font-weight: 500;
  color: ${(props) => props.color};
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export default Failure;
