import React, { useRef } from 'react';
import styled from 'styled-components';
import Tab from './tab';

const Tabs = ({ categories, theme = 'DEFAULT' }) => {
  const tabsRef = useRef(null);
  return (
    <TabsWrapper ref={tabsRef}>
      {categories.map((category) => (
        <Tab
          theme={theme}
          key={category.id}
          categoryName={category.name}
          categoryId={category.id}
          tabsRef={tabsRef}
        />
      ))}
    </TabsWrapper>
  );
};

export default Tabs;

const TabsWrapper = styled.div`
  z-index: 2;
  top: 8vh;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 35px 0 25px 0;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  & > div {
    margin: 0 1.33vw;
  }
  & > div:last-child {
    margin: 0 8vw 0 1.33vh;
  }
  & > div:first-child {
    margin: 0 1.33vw 0 8vw;
  }
`;
