/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { API } from 'utils/API';

const CREATE_ORDER = 'ORDER/CREATE';
const PAY_ORDER = 'ORDER/PAY';
const UPDATE_ORDER = 'ORDER/UPDATE';

export const createOrder = createAsyncThunk(
  CREATE_ORDER,
  async (order, { rejectWithValue }) => {
    try {
      const { data } = await API.post('/order', order);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const payOrder = createAsyncThunk(
  PAY_ORDER,
  async ({ orderNo, token }, { rejectWithValue }) => {
    try {
      const response = await API.post(`/order/pay`, { oid: orderNo, token });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateOrder = createAction(UPDATE_ORDER);

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    current: null,
    isCreating: false,
    isPaid: false,
    isPaying: false,
  },
  extraReducers: {
    // Order Create
    [createOrder.pending]: (state) => {
      state.isCreating = true;
    },
    [createOrder.fulfilled]: (state, action) => {
      state.isCreating = false;
      state.isPaid = false;
      state.current = action.payload;
    },
    [createOrder.rejected]: (state) => {
      state.isCreating = false;
    },
    // Order Finalize
    [payOrder.pending]: (state) => {
      state.isPaying = true;
    },
    [payOrder.fulfilled]: (state, action) => {
      state.isPaying = false;
      state.isPaid = true;
      state.current = action.payload;
    },
    [payOrder.rejected]: (state) => {
      state.isPaying = false;
      state.isPaid = false;
    },
    [updateOrder]: (state, action) => {
      state.current = action.payload;
    },
  },
});

export default orderSlice;
