import React, { useCallback } from 'react';
import Dropdown from 'react-dropdown';
import { ReactComponent as ArrowDown } from 'assets/svg/caret-down-outline.svg';

const labelledLanguages = (languages) =>
  languages.map((lang, index) => {
    let flag = null;

    if (lang === 'fa') {
      flag = '🇮🇷';
    }
    else if (lang === 'ar') { // sorry messi
      flag = '🇸🇦';
    } else {
      flag = (lang === 'en' ? 'gb' : lang)
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        );
    }

    return {
      value: lang,
      label: ` ${flag} ${lang.toUpperCase()}`,
      className: `flex items-center h-12 ${
        index + 1 !== languages.length &&
        'border-solid border-b border-gray-900'
      }`,
    };
  });

const LanguageSwitcher = ({
  languages = [],
  activeLanguage,
  onLanguageChange,
}) => {
  const handleLanguageChange = useCallback((lang) => {
    onLanguageChange(lang.value);
  }, []);

  return (
    <div className="font-barlow flex z-10 h-full">
      <Dropdown
        className="flex flex-col-reverse transition duration-500 ease-in-out border-l-2 border-black h-full bg-gray-800 rounded-r-lg"
        controlClassName="border-none text-white py-4 px-6 h-full flex flex-col-reverse justify-center"
        placeholderClassName="border-none text-white"
        menuClassName="border-none text-white rounded-t-lg bg-gray-800 px-2"
        arrowClassName="text-white"
        options={labelledLanguages(languages)}
        onChange={handleLanguageChange}
        arrowClosed={
          <span className="rotate-180">
            <ArrowDown width={20} height={20} fill="white" />
          </span>
        }
        arrowOpen={
          <span className="rotate-0">
            <ArrowDown width={20} height={20} fill="white" />
          </span>
        }
        value={activeLanguage}
      />
    </div>
  );
};

export default LanguageSwitcher;
